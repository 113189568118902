/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { CardOutline, Spinner } from '../../components'
import './how-to-avail-styles.scss'
import { getUrlMedia } from '../../helpers'

const List = ({ data }) => {
  let list
  
  const listItem = data.sub_category
    ? _.get(data, 'sub_category')
    : _.get(data, 'products')

  if (_.get(data, 'sub_category')) {
    list = _.map(listItem, (item, idx) => (
      <div className="cardList__continer" key={idx}>
        <span>{item.name}</span>
        <ul>
      {_.map(item.products, (pro, index) => {
        if (pro.documents.forms[index] != undefined) {
          const documentLink = pro.documents.forms[index].link;          
          return (
            // <li></li>
            <li key={index}>
              {documentLink && documentLink !== '' ? (
                <a href={getUrlMedia(documentLink)}>
                  {pro.name}
                </a>
              ) : (
                <a href="#">
                  {pro.name}
                </a>
              )}
            </li>
          );  
        } else {
          return (
            <li><a href={getUrlMedia(pro.documents.forms[0].link)}>
            {pro.name}
          </a></li>              
          );  
        }
      })}
    </ul>

      {/* <ul>
          {_.map(item.products, (pro) => (
            <li>
              <Link to={`/avail/${pro.slug}`}>{pro.name}</Link>
            </li>
          ))}
        </ul> */}
      </div>
    ))
  } else {
    list = (
      <div className="cardList__continer"> 
      <ul>
        {_.map(listItem, (pro, idx) => {
          // Retrieve the corresponding document link for the current product
                    
          if (pro.documents.forms[idx] != undefined) {
            const documentLink = pro.documents.forms[idx].link;
            return (
              // <li></li>
              <li key={idx}>
                {documentLink && documentLink !== '' ? (
                  <a href={getUrlMedia(documentLink)}>
                    {pro.name}
                  </a>
                ) : (
                  <a href="#">
                    {pro.name}
                  </a>
                )}
              </li>
            );  
          } else {
            return (
              <li><a href="#">
              {pro.name}
            </a></li>              
            );  
          }
          
        })}
      </ul>
      {/* <ul>
          {_.map(listItem, (pro) => (
            <li>
              <Link to={`/avail/${pro.slug}`}>{pro.name}</Link>
            </li>
          ))}
        </ul> */}
      </div>
    )
  }

  return list
}

const HowToAvailView = ({ loading, data }) => {
  const { t } = useTranslation()

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="container-fluid">
      <div className="container-xxl">
        <div className="howToAvail__head">
          <h1 className="page-title">{t('howToAvail.title')}</h1>
          <div
            className="rich-text howToAvail__head__description"
            dangerouslySetInnerHTML={{ __html: _.get(data, 'subtitle') }}
          />
        </div>
        {/* <div className="howToAvail__cards row">
          {_.map(_.get(data, 'list'), (item) => ( 
            <div
              className="col-sm-3 col-12 howToAvail__card"
              key={_.get(item, 'title')}
            >
              <CardOutline withoutImg={true} title={_.get(item, 'title')}>
                <div>
                  <List data={item} />
                </div>
              </CardOutline>
            </div>
          ))}
        </div> */}
        <div className="howToAvail__cards row">          
          {_.map(_.get(data, 'list'), (item) => (            
            _.get(item, 'title') === 'Medical Plans' ? (
              <div
                className="col-sm-3 col-12 howToAvail__card"
                key={_.get(item, 'title')}
              >
                <CardOutline withoutImg={true} title={_.get(item, 'title')}>
                  <div>
                    <List data={item} />
                  </div>
                </CardOutline>
              </div>
            ) : null // Don't render the card if the title is not "medical-plans"
          ))}
        </div>
      </div>
    </div>
  )
}

export default HowToAvailView
